<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`SOLICITUD DE INGRESO (${data.rha_documents.length})`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_documents.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_documents"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_documents + '/rhas/' + item.url"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" v-if="data.express_letter && rha.rha_type_id != 2">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'CARTA EXPRÉS (1)'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th v-text="'Tipo'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold" v-text="'1'" />
                      <td v-text="rha.created_at" />
                      <td v-text="'CARTA EXPRÉS'" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_pdf + '/cartaexpres/' + rha_id"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col> -->

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_demands.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `CARTA EXPRÉS PETICIONES (${data.rha_demands.length})`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_demands.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_demands"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_pdf + '/cartaexpres/demand/' + item.id"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_amounts.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`MONTOS (${data.rha_amount_documents.length})`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_amount_documents.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_amount_documents"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_documents + '/rha_amounts/' + item.url"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_evolutions.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `NOTAS DE EVOLUCIÓN (${data.rha_evolution_documents.length})`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table
                dense
                v-if="data.rha_evolution_documents.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_evolution_documents"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="
                                url_documents + '/rha_evolutions/' + item.url
                              "
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_surgicals.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `NOTAS QUIRÚRGICAS (${data.rha_surgical_documents.length})`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table
                dense
                v-if="data.rha_surgical_documents.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_surgical_documents"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="
                                url_documents + '/rha_surgicals/' + item.url
                              "
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_visits_surveys.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="`ENCUESTA`" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th v-text="'Tipo'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold" v-text="'1'" />
                      <td
                        v-text="survey.created_at ? survey.created_at : '-'"
                      />
                      <td
                        v-text="
                          survey.survey_stadistic_id
                            ? survey.survey_stadistic
                            : 'SIN CONTESTAR'
                        "
                      />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="
                                survey_file
                                  ? url_documents + '/rha_surveys/' + survey.url
                                  : url_pdf + '/encuesta/' + rha_id
                              "
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_demands.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`PETICIONES (${data.rha_demand_documents.length})`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_demand_documents.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_demand_documents"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_documents + '/rha_demands/' + item.url"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_openings.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`APERTURAS (${data.rha_aperturas.length})`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_aperturas.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_aperturas"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_documents + '/rha_opening/' + item.url"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="$store.getters.getLogin.permissions.rha_loas.read"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`LOAS (${data.rha_loas.length})`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-simple-table dense v-if="data.rha_loas.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th width="200px" v-text="'Fecha'" />
                      <th width="300px" v-text="'Tipo'" />
                      <th v-text="'Descripción'" />
                      <th width="50px" v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data.rha_loas"
                      :key="`${i}_${item.url}`"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.document" />
                      <td v-text="item.description" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              fab
                              outlined
                              class="mr-1"
                              color="warning"
                              :href="url_documents + '/rha_loas/' + item.url"
                              target="_blank"
                            >
                              <v-icon v-text="'mdi-download'" />
                            </v-btn>
                          </template>
                          <span v-text="'Descargar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, URL_DOCUMENTS, URL_PDF } from "../../control";
import { index, show } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      api_url: "rha/documents",
      url_documents: URL_DOCUMENTS,
      url_pdf: URL_PDF,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      loading: true,
      data: null,
      rha: null,
      survey: null,
      survey_file: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;

      show("insureds", this.login.token, this.rha.insured_id).then(
        (response) => {
          const express_letter = response
            ? response.contractor.express_letter === 1
              ? true
              : false
            : false;

          index(`${this.api_url}/${this.rha_id}`, this.login.token, []).then(
            (response) => {
              this.data = response.data;
              this.data.express_letter = express_letter;

              show(
                "rha/visit/survey/response/survey",
                this.login.token,
                this.rha_id
              ).then((response) => {
                //console.log(response);
                this.survey = response;

                if (this.survey.id) {
                  this.survey.survey_stadistic = null;
                  switch (this.survey.survey_stadistic_id) {
                    case 1:
                      this.survey.survey_stadistic = "SE CUENTA CON ENCUESTA";
                      break;
                    case 2:
                      this.survey.survey_stadistic = "RECHAZO Y/O REEMBOLSO";
                      break;
                    case 3:
                      this.survey.survey_stadistic = "CASO AMBULATORIO";
                      break;
                    case 4:
                      this.survey.survey_stadistic = "ALTA EN HORAS INHABILES";
                      break;
                    case 5:
                      this.survey.survey_stadistic = "NO SE CONTESTO ENCUESTA";
                      break;
                    case 6:
                      this.survey.survey_stadistic = "ENCUESTA DIGITAL";
                      break;
                  }

                  if (
                    (this.survey.url &&
                      this.survey.survey_stadistic_id === 1) ||
                    this.survey.survey_stadistic_id === 6
                  ) {
                    this.survey_file = true;
                  }
                }

                this.loading = false;
              });
            }
          );
        }
      );
    });
  },
};
</script>