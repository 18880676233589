var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{
            name: _vm.page_route ? (_vm.page_route + ".detalle") : 'home',
            params: { id: _vm.rha_id },
          }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})])],1)],1)],1),(!_vm.loading)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('RhaData',{attrs:{"rha_id":_vm.rha_id}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("SOLICITUD DE INGRESO (" + (_vm.data.rha_documents.length) + ")"))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_documents.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_documents),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rhas/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,1146362663)}):_vm._e()],1)],1)],1),(_vm.$store.getters.getLogin.permissions.rha_demands.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                  ("CARTA EXPRÉS PETICIONES (" + (_vm.data.rha_demands.length) + ")")
                )}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_demands.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_demands),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_pdf + '/cartaexpres/demand/' + item.id,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,3339058251)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_amounts.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("MONTOS (" + (_vm.data.rha_amount_documents.length) + ")"))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_amount_documents.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_amount_documents),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_amounts/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,30315303)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_evolutions.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                  ("NOTAS DE EVOLUCIÓN (" + (_vm.data.rha_evolution_documents.length) + ")")
                )}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_evolution_documents.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_evolution_documents),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_evolutions/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,1975925159)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_surgicals.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                  ("NOTAS QUIRÚRGICAS (" + (_vm.data.rha_surgical_documents.length) + ")")
                )}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_surgical_documents.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_surgical_documents),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_surgicals/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,3448119079)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_visits_surveys.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s("ENCUESTA")}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s('1')}}),_c('td',{domProps:{"textContent":_vm._s(_vm.survey.created_at ? _vm.survey.created_at : '-')}}),_c('td',{domProps:{"textContent":_vm._s(
                        _vm.survey.survey_stadistic_id
                          ? _vm.survey.survey_stadistic
                          : 'SIN CONTESTAR'
                      )}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.survey_file
                                ? _vm.url_documents + '/rha_surveys/' + _vm.survey.url
                                : _vm.url_pdf + '/encuesta/' + _vm.rha_id,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,false,2361324602)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])])]},proxy:true}],null,false,1648128259)})],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_demands.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("PETICIONES (" + (_vm.data.rha_demand_documents.length) + ")"))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_demand_documents.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_demand_documents),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_demands/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,2508834663)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_openings.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("APERTURAS (" + (_vm.data.rha_aperturas.length) + ")"))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_aperturas.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_aperturas),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_opening/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,83530953)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.getters.getLogin.permissions.rha_loas.read)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("LOAS (" + (_vm.data.rha_loas.length) + ")"))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[(_vm.data.rha_loas.length > 0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"},domProps:{"textContent":_vm._s('#')}}),_c('th',{attrs:{"width":"200px"},domProps:{"textContent":_vm._s('Fecha')}}),_c('th',{attrs:{"width":"300px"},domProps:{"textContent":_vm._s('Tipo')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{attrs:{"width":"50px"},domProps:{"textContent":_vm._s('Acción')}})])]),_c('tbody',_vm._l((_vm.data.rha_loas),function(item,i){return _c('tr',{key:(i + "_" + (item.url))},[_c('td',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(i + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('td',{domProps:{"textContent":_vm._s(item.document)}}),_c('td',{domProps:{"textContent":_vm._s(item.description)}}),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","fab":"","outlined":"","color":"warning","href":_vm.url_documents + '/rha_loas/' + item.url,"target":"_blank"}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Descargar')}})])],1)])}),0)]},proxy:true}],null,false,2209930583)}):_vm._e()],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }